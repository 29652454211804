// To see this message, add the following to the `<head>` section in your
// views/layouts/application.html.erb
//
//    <%= vite_client_tag %>
//    <%= vite_javascript_tag 'application' %>
// console.log('Vite ⚡️ Rails')

// If using a TypeScript entrypoint file:
//     <%= vite_typescript_tag 'application' %>
//
// If you want to use .jsx or .tsx, add the extension:
//     <%= vite_javascript_tag 'application.jsx' %>

// console.log('Visit the guide for more information: ', 'https://vite-ruby.netlify.app/guide/rails')

// Example: Load Rails libraries in Vite.
//
// import * as Turbo from '@hotwired/turbo'
// Turbo.start()
//
// import ActiveStorage from '@rails/activestorage'
// ActiveStorage.start()
//
// // Import all channels.
// const channels = import.meta.globEager('./**/*_channel.js')

// Example: Import a stylesheet in app/frontend/index.css
// import '~/index.css'

// import 'virtual:windi.css'

import { createApp } from 'vue/dist/vue.esm-bundler'
import axios from 'axios'
import VueDatePicker from '@vuepic/vue-datepicker';
import '@vuepic/vue-datepicker/dist/main.css';
import './user.scss'

const csrfToken = document.querySelector('meta[name="csrf-token"]').getAttribute('content');

const axiosInstance = axios.create({
  timeout: 6000,
  headers: {
    'X-CSRF-Token': csrfToken,
  }
});

const app = createApp({
  data() {
    return {
      inputs: {
        clinic_id: "",
        department_id: "",
        booking_date: new Date()
      },
      bookingInputs: {
        working_hour: null,
        full_name: "Nguyen Van A",
        gender: "male",
        email: "benhnhan@gmail.com",
        phone_number: "012345678",
        birthdate: "1992-09-27",
        address: "Ha noi",
        notes: "Bi Om",
      },
      searched: false,
      searching: false,
      doctors: [],
      isBooking: false,
      error_message: null,
      appointment: {},
    }
  },
  mounted() {
    this.hiddenLoading()
  },
  computed: {
    formInvalid() {
      return this.bookingInputs.working_hour == null ||
      this.bookingInputs.full_name == "" ||
      this.bookingInputs.email == "" ||
      this.bookingInputs.phone_number == "" ||
      this.bookingInputs.birthdate == "" ||
      this.bookingInputs.address == ""
    }
  },
  methods: {
    async searchDoctores() {
      this.showLoading()

      try {
        this.searching = true
        const { data } = await axios.get('/doctors/search', { params: this.inputs })
        this.doctors = data.doctors
      } catch (error) {

      } finally {
        this.searched = true
        this.searching = false

        this.hiddenLoading()
      }
    },

    selectWorkingDate(workingHour) {
      this.bookingInputs.working_hour = workingHour
    },

    confirmBooking() {
      document.getElementById('popup_confirm').showModal()
    },

    isCurrentHour(workingHour) {
      return this.bookingInputs.working_hour == workingHour
    },

    async submitBooking() {
      const bookingUrl = document.querySelector('#booking_url').getAttribute('value');

      try {
        this.showLoading()
        this.isBooking = true
        const { data } = await axiosInstance.post(bookingUrl, this.bookingInputs)
        this.appointment = data.appointment
        // document.getElementById('booking_success_modal').showModal()
        location.href = data.checking_url
      } catch (error) {
        this.showErrorModal(error.response.data.message)
      } finally {
        this.isBooking = false
        this.hiddenLoading()
      }
    },
    async copyText(text) {
      await navigator.clipboard.writeText(text);

      // Alert the copied text
      alert("Đã sao chép: " + text);
    },
    showErrorModal(message) {
      this.error_message = message
      document.getElementById('error_modal').showModal()
    },
    showLoading() {
      document.getElementById('block-loading')?.classList?.remove('hidden')
    },
    hiddenLoading() {
      document.getElementById('block-loading')?.classList?.add('hidden')
    }
  },
  components: { Datepicker: VueDatePicker },
})

app.mount('[data-behavior="vue"]')
